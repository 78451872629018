import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "../theme/agency";
import { ResetCSS } from "../assets/css/style";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../containers/reusableItem/agency.style";
import Navbar from "../containers/reusableItem/Navbar";
import Banner from "../containers/reusableItem/reusableBanner/background";
import BannerContent from "../containers/reusableItem/reusableBanner/bannerContent";
import Footer from "../containers/reusableItem/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";
import styles from "../css/single-blog.module.css";
import Text from "../reusecore/Text";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const productTemplate = ({ data }) => {
  const { title, imageProduct, descProduct } = data.detailProduct;

  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={slug}>{title}</Link>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => <Image {...node.data.target} />,
    },
  };

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Agency" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent title={<FormattedMessage id="rincianprodukBan" />}/>
          </Banner>
          <section className={styles.blog}>
            <div className={styles.center}>
              <div className={styles.rinciantitle}>
                <Text content={<FormattedMessage id="rincianproduk" />} />
              </div>
              <Image
                fluid={imageProduct.fluid}
                alt="image empty"
                className={styles.filterImg}
              />
              <div className={styles.konten}>
                <h3>{title}</h3>
                <p>{descProduct && renderRichText(descProduct, options)}</p>
              </div>
            </div>
          </section>
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};

export const query = graphql`
  query getProduct($slugProduct: String!, $locale: String) {
    detailProduct: contentfulProduct(
      slugProduct: { eq: $slugProduct }
      node_locale: { eq: $locale }
    ) {
      title: titleProduct
      slug: slugProduct
      node_locale
      descProduct {
        raw
      }
      imageProduct {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default productTemplate;
